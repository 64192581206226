







































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component
export default class PurchPriceMatrix extends Vue {
  private isLoading = false
  private data = null
  private showOnlyErrors = false

  private get customerNumber() {
    return this.$route.query?.customer || ''
  }

  public mounted(): void {
    this.load()
  }

  private isZero(value): boolean {
    if (!value) {
      return true
    }
    if (value === 0 || value === '0') {
      return true
    }
    return false
  }

  private hasFixedPrice(detail): boolean {
    return !this.isZero(detail.fixedPrice)
  }

  private hasDiscountPercent(detail): boolean {
    return !this.isZero(detail.discountPercent)
  }

  private formatPeriod(detail): string {
    const from = this.formatDate(detail.firstValidityDate)
    const to = this.formatDate(detail.expiryDate)
    if (!from && !to) {
      return ''
    }
    return from + ' - ' + to
  }

  private formatDate(value): string {
    if (!value) {
      return ''
    }
    const parts = value.split('T')
    return parts[0]
  }

  private getStatusText(index) {
    if (this.data.errors[index]) {
      return 'ERROR: ' + this.data.errors[index]
    }
    return 'OK'
  }

  private getStatusClass(index) {
    if (this.data.errors[index]) {
      return 'status-error'
    }
    return 'status-ok'
  }

  private isError(index) {
    return !!this.data.errors[index]
  }

  private load() {
    const customerNumber = this.customerNumber
    if (customerNumber) {
      this.isLoading = true
      this.$axios
        .get('/v4/site/integrations/dackia/purch-price-matrix?customer=' + customerNumber)
        .then((response) => {
          this.data = response.data.data
          this.isLoading = false
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed to load matrix')
          this.isLoading = false
        })
    }
  }
}
